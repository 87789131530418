import React, { useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import { InputPassword } from "@jobber/components/InputPassword";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Form } from "@jobber/components/Form";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import { Banner } from "@/components/Banner";
import { LoginPrompt, TermsOfServicePrompt } from "../components";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";

interface SignupProps {
  name?: string;
  email?: string;
  isLoading: boolean;
  onSignupAttempt(params: SignupAttemptParameters): void;
}

interface SignupAttemptParameters {
  name: string;
  email: string;
  password: string;
}

export function Signup({
  name,
  email,
  isLoading,
  onSignupAttempt,
}: SignupProps) {
  const [inputs, setInputs] = useState({
    name: name || "",
    email: email || "",
    password: "",
  });

  const [didNotAcceptTermsError, setDidNotAcceptTermsError] = useState(false);
  const [userAcceptedTerms, setUserAcceptedTerms] = useState(false);

  const header = email ? "Join your team’s account" : "Create an account";
  const description = `Begin building solutions ${
    email ? "as a team" : ""
  } to make small businesses more successful`;

  return (
    <div className={styles.common_wrapper}>
      <Banner />
      <JobberDevCenterLabel />
      <Form onSubmit={handleSignupSubmit}>
        <Content>
          <div className={styles.signup_header}>
            <Heading level={2}>{header}</Heading>
          </div>
          <div className={styles.signup_subheading}>
            <Text>{description}</Text>
          </div>
          <div className={styles.validation_message}>
            <InputText
              placeholder="Name"
              size="large"
              autocomplete={false}
              defaultValue={name}
              validations={{
                required: {
                  value: true,
                  message: "Please provide a name",
                },
              }}
              onChange={handleInputChange({ input: "name" })}
            />
          </div>
          <div className={styles.validation_message}>
            <InputText
              placeholder="Email"
              size="large"
              defaultValue={email}
              disabled={!!email}
              validations={{
                required: {
                  value: true,
                  message: "Please provide an email",
                },
              }}
              onChange={handleInputChange({ input: "email" })}
            />
          </div>
          <div className={styles.validation_message}>
            <InputPassword
              placeholder="Password"
              size="large"
              hasVisibility
              description="Password must be 10 or more characters"
              validations={{
                required: {
                  value: true,
                  message: "Please provide a password",
                },
                minLength: {
                  value: 10,
                  message: "Password must be 10 or more characters",
                },
              }}
              onChange={handleInputChange({ input: "password" })}
            />
          </div>
          <div className={styles.validation_message}>
            <InputPassword
              placeholder="Confirm password"
              size="large"
              hasVisibility
              validations={{
                required: {
                  value: true,
                  message: "Please confirm your password",
                },
                validate: onPasswordConfirmationValidation,
              }}
            />
          </div>
          <TermsOfServicePrompt
            acceptedTermsOfService={userAcceptedTerms}
            onAcceptedTermsOfService={handleUserAcceptedTerms}
          />
          <div className={styles.validation_message}>
            {didNotAcceptTermsError && (
              <Text variation="error">
                Agreement required to create an account
              </Text>
            )}
          </div>
          <Divider />
          <Button
            label="Sign Up"
            size="large"
            fullWidth
            loading={isLoading}
            submit
          />
          <LoginPrompt />
        </Content>
      </Form>
    </div>
  );

  function handleInputChange({
    input,
  }: {
    input: "name" | "email" | "password";
  }) {
    if (email && input === "email") return;

    return (value: string) =>
      setInputs(current => ({ ...current, [input]: value }));
  }

  function onPasswordConfirmationValidation(value: string) {
    const { password } = inputs;
    if (value !== password) return "Your password doesn't match";
    return true;
  }

  function handleSignupSubmit() {
    if (!userAcceptedTerms) {
      setDidNotAcceptTermsError(true);
      return;
    }
    onSignupAttempt(inputs);
  }

  function handleUserAcceptedTerms(checkboxValue: boolean) {
    setUserAcceptedTerms(checkboxValue);
  }
}
