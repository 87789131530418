import React from "react";
import { Page } from "@/components/Page";
import { Signup } from "@/features/Authentication/Signup";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";

function SignUpPage() {
  return (
    <Page variation="presession">
      <Signup />
    </Page>
  );
}

export default withoutAuthentication(SignUpPage);
